<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Zahlenrechnen – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Welche Facetten des Hintergrundwissens konnten beim Zahlenrechnen ausgemacht werden?
        </v-card-title>
        <v-card-text
          >Das Hintergrundwissen im Bereich Zahlenrechnen lässt sich
          aufgliedern in die drei Facetten:
          <ul>
            <li>Unterschiedliche Strategien zum halbschriftlichen Rechnen,</li>
            <li>deren flexibler Einsatz sowie</li>
            <li>Unterschiedliche Darstellungsweisen.</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem auf für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau Probleme auftreten und daran
          anschließend passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie im Bereich der Diagnose und Förderung im
          Zahlenrechnen?
        </v-card-title>
        <v-card-text
          >In mehreren Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          <ul>
            <li>
              typische Fehler im Bereich des halbschriftlichen Rechnens
              nachzuvollziehen und nach Gemeinsamkeiten zu ordnen,
            </li>
            <li>
              Fehler im Bereich des halbschriftlichen Rechnens zu
              diagnostizieren und
            </li>
            <li>Förderaufgaben diagnosegeleitet auszuwählen.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>

    <AppBottomNavHR
      next="/zahlenrechnen/diagnose-und-foerderung"
      back="/zahlenrechnen/hintergrundwissen/darstellungsweisen-beim-halbschriftlichen-rechnen"
    />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
export default {
  components: {
    AppBottomNavHR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
