<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Zahlenrechnen",
          to: "/zahlenrechnen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/zahlenrechnen/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Strategien des Halbschriftlichen Rechnens",
          to: "/zahlenrechnen/hintergrundwissen/strategien-des-halbschriftlichen-rechnens",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Flexibler Einsatz von Rechenstrategien",
          to: "/zahlenrechnen/hintergrundwissen/flexibler-einsatz-von-rechenstrategien",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Darstellungsweisen beim Halbschriftlichen Rechnen",
          to: "/zahlenrechnen/hintergrundwissen/darstellungsweisen-beim-halbschriftlichen-rechnen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/zahlenrechnen/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/zahlenrechnen/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/zahlenrechnen/check",
        },
      ],
    };
  },
};
</script>