<template>
  <v-bottom-navigation
    horizontal
    absolute
    height="auto"
    style="padding-left: 0px"
  >
    <v-row no-gutters align="center">
      <v-col lg="9" md="8" cols="5" align="left">
        <v-breadcrumbs
          divider="➞"
          :items="items"
          exact-active-class="activeBreadcrump"
          @click.native="clickedOnBreadcrumbs"
          class="darkPetrol"
        >
        </v-breadcrumbs>
      </v-col>
      <v-col lg="3" md="4" cols="7" align="right" style="height: 100%">
        <v-btn
          :to="back"
          :disabled="!back"
          class="navButton"
          exact
          text
          @click="clickedOnBack"
        >
          <span :style="back ? 'color: black' : ''">Zurück</span>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn
          :to="next"
          :disabled="!next"
          class="navButton"
          exact
          text
          @click="clickedOnNext"
        >
          <v-icon class="darkPetrol">mdi-arrow-right</v-icon>
          <span :class="next ? 'darkPetrol' : ''">Weiter</span>
        </v-btn>
      </v-col></v-row
    >
  </v-bottom-navigation>
</template>

<script>
import AppHelper from "@/common/AppHelper";
export default {
  props: {
    items: Array,
    next: String,
    back: String,
  },
  methods: {
    clickedOnBreadcrumbs(event) {
      AppHelper.trackMatomoEvent(
        this,
        "AppBottomNav",
        "id:56; Breadcrump angeklickt; pathname: " +
          event.srcElement.pathname +
          ";",
        "AppBottomNav"
      );
    },
    clickedOnBack(event) {
      AppHelper.trackMatomoEvent(
        this,
        "AppBottomNav",
        "id:57; Zurück angeklickt; pathname: " +
          event.currentTarget.pathname +
          ";",
        "AppBottomNav"
      );
    },
    clickedOnNext(event) {
      AppHelper.trackMatomoEvent(
        this,
        "AppBottomNav",
        "id:58; Weiter angeklickt; pathname: " +
          event.currentTarget.pathname +
          ";",
        "AppBottomNav"
      );
    },
  },
};
</script>

<style>
/*
 * scoped geht nicht, weil wir css von v-breadcrumbs überschreiben wollen
 */

#app .v-breadcrumbs__item--disabled {
  color: black !important;
  font-weight: 500;
}
#app .v-breadcrumbs li {
  font-size: 12px; /* 14px wäre default */
}

/*
 * Die Hauptgruppen (Thema, Hintergrundwissen, Übergang Diagnose, Kompetenzliste) sollen etwas größer sein also die anderen Elemente,
 * um die hierarchische Struktur anzudeuten. Die Magic-Numbers 1,3,11,15 (das sind die Positionen der Kindelemente) dafür zu nutzen war der einfachste weg.
 */
#app .v-breadcrumbs li:nth-child(1) {
  font-size: 14px;
}
#app .v-breadcrumbs li:nth-child(3) {
  font-size: 14px;
}
#app .v-breadcrumbs li:nth-child(11) {
  font-size: 14px;
}
#app .v-breadcrumbs li:nth-child(15) {
  font-size: 14px;
}

#app .navButton {
  height: 100%;
}

.v-breadcrumbs__item, .v-application a{
  color: #477B7E
}
</style>
